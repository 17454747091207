@import "./variables";

footer {
  .footer {
    margin: 120px 0 30px;
    border: 1px solid $primary;
    border-radius: 20px;
    padding: 35px;
    height: 100%;
    min-height: 250px;
  }
}

header {
  > nav {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .w-100;
    gap: 30px;
    height: $header-height;
    padding: 0 50px;
    border-bottom: 1px solid #455A64;
    background: $background-color;
    margin-bottom: 20px;
  }
}

.hero-section {
  background-color: #1F2227;
  max-height: 300px;
  padding: 20px 0;
  width: 100%;
  margin-top: $header-height;
}

.inner-content {
  position: relative;
  display: flex;

  main {
    flex: 1;
    overflow-y: auto;
    padding: 0 5px;
    margin-right: 250px;
  }

  aside {
    width: 280px;
    padding: 20px;
    position: fixed;
    top: $header-height;
    right: 0;
    height: 100%;
    display: block;
  }
}

.main-content {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-between;
  margin-left: $sidebar-width;
  transition: none;
  //height: 100vh;
  padding: ($header-height + 40px) 50px 10px;
  overflow-x: hidden;
}

.scroll-to-top {
  @extend .position-fixed;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .text-white;
  @extend .box-shadow;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: $primary;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
  transition: opacity 2.5s ease-in-out;

  &:hover {
    background-color: lighten($primary, 10px);
  }
}

.sidebar {
  &.side-menu {
    padding: 12px;
    overflow: hidden;
    height: 100vh;
  }

  &-sticky {
    z-index: 3;
    max-width: 100%;
    width: $sidebar-width;
    box-shadow: none;
    position: fixed;
    top: $header-height;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    flex-shrink: 0;
    flex-direction: column;
    border-right: 1px solid #455A64;
    padding: 30px 0;
    background: $background-color;

    .nav {
      width: 100%;
      border-top: 1px solid #455A64;
      padding-top: 20px;

      .nav-submenu {
        max-height: 100%;
        overflow: hidden;
        transition: all 0.5s;
        padding-left: 7px;
      }

      &-link {
        @extend .d-flex;
        @extend .justify-content-between;
        @extend .text-decoration-none;
        color: $gray-400;
        font-size: 14px;
        line-height: 1.25rem;
        padding: 7px;

        &:hover {
          @extend .text-decoration-underline;
          font-weight: 500;
        }
      }

      li.nav-item {
        cursor: pointer;
        width: 100%;
        margin: 7px 10px;
        list-style-type: none;

        &.show {
          .nav-link {
            font-weight: 600;
          }
        }

        &.active {
          .nav-link {
            color: $white;
            font-weight: 700;
            width: inherit;
            height: inherit;
            border-radius: 0 15px 15px 0;
            border-left: 3px solid $primary;
            background-color: rgba($color: #ffffff, $alpha: .09);
          }
        }
      }
    }
  }
}


@media (max-width: 1200px) {
  .inner-content {
    main {
      margin-right: 0;
    }

    aside {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  header {
    > nav {
      padding: 0 15px;
    }
  }

  .main-content {
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .sidebar {
    &-sticky {
      width: calc(100% - 20px);
    }
  }

  .main-content {
    padding: ($header-height + 10px) 30px 10px;
  }
}