@import "./variables";

.box-shadow {
  box-shadow: 1px 3px 15px #455A64;
}

.breadcrumb {
  &-item {
    font-size: 13px;

    a,
    &+.breadcrumb-item::before {
      color: $medium;
    }

    &.active {
      color: #eaeaea;
    }
  }
}


.page-loader {
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;

  &-backdrop {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.05);
      content: "";
      z-index: 1;
    }
  }
}

.spinner {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.double-bounce {
  &1 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  &2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

}


@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}