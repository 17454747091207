@import "./variables";


:root {
  --primary-bg-color: #2d3892;
  --primary-bg-hover: #2b3691;
  --primary-transparentcolor: rgba(45, 56, 146, 0.16);
  --primary-bg-border: #2D3892;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: $white;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2D3892;
  --secondary: #3C91E6;
  --success: #0F973D;
  --info: #FFAB00;
  --warning: #ffc107;
  --danger: #CB1A14;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;
  --font-family-monospace: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;
  --bs-heading-color: $medium;
}

* {
  box-sizing: border-box;
  font-family: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-family: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body {
  margin: 0;
  padding: 0;
  font-family: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.16px;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
  -ms-overflow-y: scroll;
  width: 100%;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: $background-color;
  color: $white !important;
}

a {
  font-style: normal;
  font-weight: 500;
  color: rgb(200, 200, 200);
  text-decoration: none;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

p,
li {
  font: 400 16px/23px Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 50px;
  line-height: 65.5px;
}

h2 {
  font-size: 40px;
  line-height: 58.9px;
}

h3 {
  font-size: 34px;
  line-height: 48px;
}

h4 {
  font-size: 25px;
  line-height: 36.25px;
}

h5 {
  font-size: 22px;
  line-height: 31.9px;
}

h6 {
  font-size: 16px;
  line-height: 18.40px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[type="number"] {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}


[hidden] {
  display: none !important;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

::-webkit-scrollbar-button {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button {
  border-radius: 3px;
  background: linear-gradient(180deg, $primary 0%, $primary 9%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border: 1px solid $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-button:hover {
  width: 3px;
  background: $primary;
}

.no-scrollbar {
  overflow: auto;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.form-control {
  background-color: transparent;
  border: 1px solid #455A64;
  color: white !important;

  &::placeholder {
    color: #455A64 !important;
  }

  &:focus,
  &:active {
    background-color: transparent;
  }
}

code[class*=language-],
pre[class*=language-] {
  color: #dcdcdc;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  letter-spacing: 1.5px;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  @extend .font-monospace;
}

pre[class*=language-] {
  background: #1F2227;
  padding: 1.5em 2em;
  border: 0.5px solid lighten(#455A64, 20%);
  border-radius: 20px;
  overflow: auto;
  width: 100%;
  max-width: 700px;
}